<div
  #filterRow
  (resized)="onResize()"
  class="row"
>
  <div class="search-filter-container">
    @if (showSearch) {
      <dpa-expandable-search
        [placeholderText]="placeholderText"
        (searchChange)="onSearchChange($event)"
        (toggleExpand)="onSearchExpandOrCollapse($event)"
      />
    }
    @if (showFilter) {
      <dpa-filter-with-pills
        [activeFilters]="activeFilters"
        [filterTemplate]="filterTemplate"
        [filterWidth]="filterWidth"
        [isSearchExpanded]="isSearchExpanded"
        (removeFilter)="onRemoveFilter($event)"
        (clearAllFilters)="onClearAllFilters()"
      />
    }
  </div>
  @if (showSort) {
    @if (sortOns.length > 2) {
      <div class="sort-container">
        <cds-icon
          shape="sort-by"
          size="16"
        />
        <dpa-combo-box
          [(selected)]="selectedSort"
          [items]="sortOns"
          [formatter]="formatter"
          [isMulti]="false"
          (selectedChange)="onSelectSort($event)"
        />
      </div>
    } @else {
      <div class="btn-group mr-0">
        @for (sortOn of sortOns; track sortOn) {
          <div
            (click)="onSelectSort(sortOn)"
            class="radio btn btn-sm"
          >
            <input
              [checked]="selectedSort.by === sortOn.by"
              [id]="sortOn.by"
              type="radio"
            />
            <label [for]="sortOn.by">{{ sortOn.label ?? sortOn.by }}</label>
          </div>
        }
      </div>
    }
  }
</div>
